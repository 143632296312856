import React, { useContext, useEffect, useState } from "react";
import { IAppContext, IGeneralSetting, IHolidays } from "../@types/IContext/IAppContext";
import { getGeneralSetting, getWorkingTime } from "../pages/api/cart";
import { getBranch, getPageSEO, getTypeUrl } from "../pages/api/hello";
import { useRouter, usePathname } from 'next/navigation'

export const initialValueGeneralSetting = {
    isLoaded: false,
    afternoon_from: null,
    afternoon_to: null,
    identity_card: null,
    identity_card_value: null,
    late_payment: null,
    maximum_reduction_amount: null,
    morning_from: null,
    morning_to: null,
    night_from: null,
    night_to: null,
    number_wash: null,
    passport: null,
    passport_value: null,
    reminder_time: null,
    rental_value: null,
    surcharge_time: null,
    vat: null,
    time_rent_package: null,
    unused: null,
    vehicle_regis_certificate: null,
    vehicle_regis_certificate_value: null,
    branchs: null,
    system_config: null,
    holidays: [],
    workingDay: [],
    comboAttachItems: [],
    attachItems: [],
}

const AppContext = React.createContext<IAppContext>({
    generalSetting: { ...initialValueGeneralSetting },
    setGeneralSetting: () => { },
    branchs: [],
    setBranchs: () => { },
    type: null,
    setTypeData: () => { },
    groupTypeProductsData: [],
    setGroupTypeProductsData: () => { },
    filterSet: null,
    setFilterSet: () => { },
    seo: null,
    setSeo: () => { },
    windowWidth: null,
    holidays: [],
    workingDay: [],
    comboAttachItems: [],
    setComboAttachItems: () => { },
    attachItems: [],
    setAttachItems: () => { },
})

export const AppContextProvider = ({ children }: { children: JSX.Element | any }) => {
    const [holidays, setHolidays] = useState<IHolidays[]>([]);
    const [workingDay, setWorkingDay] = useState<Number[]>([]);
    const [comboAttachItems, setComboAttachItems] = useState<any>([]);
    const [attachItems, setAttachItems] = useState<any>([]);
    const router = usePathname();
    const [generalSetting, setGeneralSetting] = useState<IGeneralSetting>({ ...initialValueGeneralSetting })
    const [branchs, setBranchs] = useState([]);
    const [type, setTypeData]: any = useState<number>(-1);
    const [groupTypeProductsData, setGroupTypeProductsData] = useState([]);
    const [filterSet, setFilterSet] = useState(null);
    const [seo, setSeo] = useState(null);
    const [windowWidth, setWindowWidth] = useState(0);

    async function loadSeo(urlInput: any) {
        try {
            if (urlInput === "") {
                let res = await getPageSEO("/" + "&type=2");
                res.keyword = res.keyword?.map((item: any) => item.keyword);
                await setSeo(res);

            } else {
                if (type !== null) {
                    let res = await getPageSEO(urlInput + "&type=" + type);
                    await setSeo(res);

                }
            }



        } catch (error) {
            console.log("error", error);
        }

    }

    async function setTypeUrl(url: string) {
        if (url !== "/[...slug") {
            let res = await getTypeUrl(url);
            if (res) {
                await setTypeData(res);
            } else {
                await setTypeData(-1);
            }
        }

    }


    useEffect(() => {
        if (!generalSetting.isLoaded) {
            getGeneralSetting().then((res) => {
                if (res) {
                    setGeneralSetting((pre) => {
                        return {
                            ...pre,
                            ...res,
                            isLoaded: true
                        }
                    })
                }
            });


        }
        if (branchs.length === 0) {
            getBranch().then((res: any) => {
                if (res) {
                    setBranchs(res.results ?? [])
                }
            });
        }
        if (window.innerWidth > 1000) {
            setWindowWidth(1);
        } else if (window.innerWidth > 768) {
            setWindowWidth(2);
        } else if (window.innerWidth > 100) {
            setWindowWidth(3);
        }
        getWorkingTime().then((res: any) => {
            setHolidays(res?.holiday);
            setWorkingDay(res?.working_time?.map((e: any) => {
                if (e.weekdays + 1 > 6) {
                    return 0;
                }
                return e.weekdays + 1;
            }))
        })
        //loadSeo(router.asPath.slice(0, router.asPath.length - 1) ?? "/");
    }, []);

    useEffect(() => {
        let url = router

        // let url = router.slice(0, router.length - 1) ?? "/"
        // if (router.indexOf(".html") > -1) {
        //     url = router.slice(0, router.length) ?? "/"
        // } else {
        //     if (url.indexOf("page") > -1) {
        //         url = url.slice(0, url.indexOf("?") - 1);
        //     }
        // }
        if (url) {
            url = router.slice(0, router.length - 1) ?? "/"
            if (router.indexOf(".html") > -1) {
                url = router.slice(0, router.length) ?? "/"
            } else {
                if (url.indexOf("page") > -1) {
                    url = url.slice(0, url.indexOf("?") - 1);
                }
            }
            getTypeUrl(url).then((res) => {
                setTypeData(res);
            });
        }
        //setTypeUrl(url)
    }, [router])

    useEffect(() => {
        let url = router
        // let url = router.slice(0, router.length - 1) ?? "/"
        // if (router.indexOf(".html") > -1) {
        //     url = router.slice(0, router.length) ?? "/"
        // } else {
        //     if (url.indexOf("page") > -1) {
        //         url = url.slice(0, url.indexOf("?") - 1);
        //     }
        // }
        if (url) {
            url = router.slice(0, router.length - 1) ?? "/"
            if (router.indexOf(".html") > -1) {
                url = router.slice(0, router.length) ?? "/"
            } else {
                if (url.indexOf("page") > -1) {
                    url = url.slice(0, url.indexOf("?") - 1);
                }
            }
            loadSeo(url);
        }

    }, [
        type, router
    ])

    return (
        <AppContext.Provider value={{
            holidays,
            windowWidth,
            seo,
            setSeo,
            filterSet,
            setFilterSet,
            type,
            setTypeData,
            generalSetting,
            branchs,
            setBranchs,
            setGeneralSetting,
            groupTypeProductsData,
            setGroupTypeProductsData,
            workingDay,
            comboAttachItems,
            attachItems,
            setComboAttachItems,
            setAttachItems
        }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext;
