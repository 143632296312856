import '../styles/globals.scss';
import type { AppProps } from 'next/app'
import { DefaultSeo } from "next-seo";
import { PaymentProvider } from "../store/PaymentContext";
import AppContext, { AppContextProvider } from "../store/AppContext";
import ToasterProvider from '../components/Toast';
import { useContext, useEffect, useLayoutEffect } from 'react';
import { useRouter } from 'next/router';
//import { GoogleAnalytics } from 'nextjs-google-analytics';
//import { gaMeasurementId } from '../@types/IProduct/IProductDetail';
import LoadingComponent from '../components/Loading';
import { pageview } from '../utils/gtag';
import dynamic from 'next/dynamic';
//import GoogleAnalyticsComp from '../components/GoogleAnalytic';

const DynamicGoogleAnalytic = dynamic(() => import('../components/GoogleAnalytic'));
export default function App({ Component, pageProps }: AppProps)  {
  const { setSeo, setTypeData } = useContext(AppContext);
  const router = useRouter();
  const SEO = {
    ...(pageProps.seo != null ? { ...pageProps.seo } : {}),
    defaultTitle: "Hoài Giang shop - Chuyên may, bán và cho thuê trang phục",
  };




  // useEffect(() => {
  //   if (window.location.pathname !== "/") {
  //     router.push(window.location.pathname + window.location.search);
  //   }

  // }, []);

  // useEffect(() => {
  //   const handleStart = (url: any) => (url !== router.pathname) && setLoading(true);
  //   const handleComplete = (url: any) => (url === router.pathname) && setLoading(false);
  //   const handleError = (url: any) => setLoading(false);
  //   router.events.on('routeChangeStart', handleStart)
  //   router.events.on('routeChangeComplete', handleError)
  //   router.events.on('routeChangeError', handleError)

  //   return () => {
  //     router.events.off('routeChangeStart', handleStart)
  //     router.events.off('routeChangeComplete', handleError)
  //     router.events.off('routeChangeError', handleError)
  //   }
  // });

  useEffect(() => {
    //callSeo()
  }, [])

  useEffect(() => {
    pageview(router.asPath)
  }, [router])

  // useEffect(() => {

  //   const handleRouteChange = () => {
  //     pageview(router.asPath);

  //   }
  //   handleRouteChange();
  //   // router.events.on('routeChangeComplete', handleRouteChange)
  //   // return () => {
  //   //   router.events.off('routeChangeComplete', handleRouteChange)
  //   // }
  // }, [router])

  //console.log("loading start", loading);

  // const callSeo = async () => {
  //   let url = router.asPath.slice(0, router.asPath.length - 1) ?? "/"
  //   if (url.indexOf("page") > -1) {
  //     url = url.slice(0, url.indexOf("?") - 1);
  //   }
  //   const urlInput = router.asPath.slice(0, router.asPath.length - 1) ?? "/"
  //   const type = await getTypeUrl(url);
  //   //await setTypeData(type);
  //   if (urlInput === "") {
  //     let res = await getPageSEO("/" + "&type=2");
  //     res.keyword = res.keyword?.map((item: any) => item.keyword);
  //     await setSeo(res);

  //   } else {
  //     if (type !== null) {
  //       let res = await getPageSEO(urlInput + "&type=" + type);
  //       await setSeo(res);

  //     }
  //   }
  // }

  return (
    <AppContextProvider>
      <PaymentProvider>
        <ToasterProvider />
        <DefaultSeo {...SEO} />
        <DynamicGoogleAnalytic />
        <Component {...pageProps} />
        <LoadingComponent loading={false} />
      </PaymentProvider>
    </AppContextProvider>
  )
}

